import React from 'react';
export default function About() {
  return (
    <div id="about_" className="text-black ">
      <div class="flex h-screen">
        <div class="m-auto">
          <div className="lg:flex lg:flex-row p-8 bg-gray-100 rounded-full opacity-75">
            <img
              className="rounded-full border-8 border-black h-80  "
              src="https://media-exp1.licdn.com/dms/image/C4D03AQHtizseucp0mw/profile-displayphoto-shrink_800_800/0/1597159341693?e=1649894400&v=beta&t=-2ZKusJxmNlciEANOqHNMMz-FWAvFB4ko272T2aj6nY"
              alt="img"
            />
            <div className="p-16">
              I am currently working as a specialist at Proton Therapy Centre
              located in Institute of Nuclear Physics PAN. Last year I completed
              postgraduate studies Data Analysis - Data Science. In my thesis I
              used data from the Center to find relationship between ion chamber
              errors and proton beam parameters. I am fascinated by the Python
              programming language and I would like to develop myself as a AI
              Developer in it as well. Currently I am looking new opportunities,
              where I can expand the boundaries of my knowledge.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
