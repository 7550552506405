import React from 'react';
import ScrollTo from 'react-scroll-into-view';
import Logo from './logo_full.svg';

export default function Navbar() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav
        id="my_nav"
        className="z-40 top-0 fixed px-16  h-20  justify-between inset-x-0  py-4 lg:flex"
      >
        <div className="flex  text-white lg:text-4xl justify-between ">
          <ScrollTo selector="#hero_">
            <span className="">
              <div className="flex  ">
                <img
                  src={Logo}
                  alt=""
                  className="src h-16 bg-white rounded-2xl"
                />
              </div>
            </span>
          </ScrollTo>

          <div>
            <button className="lg:hidden">
              <i
                className="fas fa-bars text-2xl text-white "
                onClick={() => {
                  setNavbarOpen(!navbarOpen);
                }}
              ></i>
            </button>
          </div>
        </div>
        <div className="">
          <div
            className={
              'lg:flex flex-grow items-center bg-black lg:bg-transparent lg:shadow-none' +
              (navbarOpen ? ' block rounded shadow-lg' : ' hidden')
            }
            id="rollingMenu"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <ul
                  className={
                    'text-white   px-2 py-2 lg:text-xl flex items-center text-semibold font-bold'
                  }
                >
                  {/* <ScrollTo selector="#hero_">
                    <li>
                      <button
                        // href="#"
                        className="inline-block hover:text-yellow-500 mx-4 "
                      >
                        HOME
                      </button>
                    </li>
                  </ScrollTo> */}
                  <ScrollTo selector="#about_">
                    <li>
                      <button
                        // href="#"
                        className="inline-block hover:text-yellow-500 mx-4 "
                      >
                        ABOUT
                      </button>
                    </li>
                  </ScrollTo>
                  <ScrollTo selector="#skills_">
                    <button className="inline-block hover:text-yellow-500 mx-4">
                      SKILLS
                    </button>
                  </ScrollTo>
                  <ScrollTo selector="#projects_">
                    <button className="inline-block hover:text-yellow-500 mx-4">
                      PROJECTS
                    </button>
                  </ScrollTo>
                  <ScrollTo selector="#resume_">
                    <button className="inline-bloc border- hover:text-yellow-500 mx-4">
                      <a href="mailto:lraczyn@softwaring.pl">
                        <i className="fas fa-file pr-2"></i>
                        CONTACT
                      </a>
                    </button>
                  </ScrollTo>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
