import Footer from './components/Footer.js';
import Navbar from './components/Navbar.js';
import Projects from './components/Projects.js';
import Skills from './components/Skills.js';
import About from './components/About.js';
import './App.css';

function App() {
  return (
    <div className="bg-black">
      <header class="relative flex items-center justify-center h-screen mb-12 overflow-hidden">
        <div class="relative z-30 p-5 text-2xl text-white  bg-opacity-50 rounded-xl">
          <About id="about_component" />
        </div>
        <video
          autoPlay
          loop
          muted
          class="absolute z-10 w-auto min-w-full min-h-full max-w-none"
        >
          <source
            src="https://assets.mixkit.co/videos/preview/mixkit-set-of-plateaus-seen-from-the-heights-in-a-sunset-26070-large.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </header>

      <Navbar />

      <Skills />
      <Projects />

      <Footer />
    </div>
  );
}

export default App;
